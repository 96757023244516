<template>
  <div class="video-alarm-list">
    <van-nav-bar left-arrow title="作业监督检查" @click-left="$router.back()" />
    <div class="search-query">
      <van-search
        v-model="queryInfo.ticketInfo"
        class="search-bar"
        placeholder="请输入名称关键词或编号"
        @clear="onRefresh"
        @search="onRefresh"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="datetime"
        format="{y}-{m}-{d} {h}:{i}"
      />
      <work-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条结果</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="video-alarm-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <workticket-item
          v-for="(item, index) in list"
          :id="item.ticketId"
          :key="item.ticketId"
          :class="{ 'block-space_8': index < list.length - 1 }"
          :title="item.ticketName"
          :status="item.workStatusName"
          :types="item.workTypeName"
          :number="item.ticketCode"
          :start-time="item.planStart"
          :end-time="item.planEnd"
          :text1="item.workDeptName"
          :text2="item.facilityName"
          :text3="item.supervisorList"
          :text4="item.approverList"
          :check-status="item.checkStatus"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getFileUrlForFileSystem } from "@/utils/file";
import { mapState } from "vuex";
import WorkQuery from "@/views/workticket/components/WorkQuery";
import { getPageWorkInspect } from "@/api/psm/workInspect";
import WorkticketItem from "@/components/workticket/WorkticketItem";
export default {
  name: "WorkInspect",
  components: { WorkticketItem, WorkQuery },
  data() {
    return {
      queryInfo: {
        orgCode: "",
        queryTimeStart: "",
        queryTimeEnd: "",
        page: 1,
        size: 10
      },
      dateTime: { start: "", end: "" },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.queryTimeStart = this.formatDate(val.start);
          this.queryInfo.queryTimeEnd = this.formatDate(val.end);
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startTime = "";
          this.queryInfo.endTime = "";
          this.onRefresh();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.queryInfo.orgCode = this.userInfo.orgCode;
  },
  activated() {
    this.onRefresh();
  },
  methods: {
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    formatDate(date) {
      // return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      return dayjs(date).valueOf();
    },
    getFileUrl(fileId) {
      return getFileUrlForFileSystem(fileId, false);
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const data = await getPageWorkInspect(this.queryInfo);
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          const newList = list.map(item => {
            item.supervisorList = [{ name: item.supervisorName }];
            item.approverList = item.approverName.map(i => {
              return { name: i };
            });
            return item;
          });
          this.list = this.list.concat(newList);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    }
  }
};
</script>

<style lang="scss">
.video-alarm-list {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .search-bar {
    padding-bottom: 0;
  }
  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    padding: 14px 0;
  }
  &__wrapper {
    overflow-y: auto;
  }
}
</style>
